import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Dummy from './components/Dummy'
import ProtectRoute from './Routes/ProtectRoute'
import Privacy from './components/Privacy'

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<ProtectRoute />}>
          <Route path="/" element={<Dummy />} />
          <Route path="/privacy" element={<Privacy />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
