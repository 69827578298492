import React from 'react'
import { ThreeDots } from 'react-loader-spinner'

const ThreeDotsLoader = () => {
  return (
    <ThreeDots
  visible={true}
  height="70"
  width="70"
  color="#6825F7"
  radius="#C325FF"
  ariaLabel="three-dots-loading"
  wrapperStyle={{}}
  wrapperClass=""
  />
  )
}

export default ThreeDotsLoader
